<template>
  <v-row v-if="widgets.length > 0">
    <v-col v-for="widget in widgets" v-bind:key="widget.id" :cols="widget.cols">
      <v-card :loading="loading">
        <v-toolbar :color="widget.color" flat dark>
          <v-toolbar-title>{{ widget.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-skeleton-loader v-if="loading" type="button"></v-skeleton-loader>
          <v-btn
            v-else
            v-for="action in widget.content.actions"
            v-bind:key="action.label"
            depressed
            small
            :to="{ name: action.link }"
            >{{ action.label }}</v-btn
          >
        </v-toolbar>
        <v-row v-if="loading" class="pl-4 pr-4">
          <v-col>
            <v-skeleton-loader type="table-cell"></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader type="table-cell"></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader type="table-cell"></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-simple-table v-else>
          <template v-slot:default>
            <thead v-if="widget.content.table.headers">
              <tr>
                <th
                  v-for="header in widget.content.table.headers"
                  v-bind:key="header"
                  :class="header === 'Actions' ? 'text-right' : 'text-left'"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rows, index) in widget.content.table.body"
                v-bind:key="index"
              >
                <td
                  v-for="(col, colIndex) in rows"
                  v-bind:key="colIndex"
                  :class="col.color"
                >
                  <span v-if="col.type === 'text'" :class="col.color">{{
                    col.content
                  }}</span>
                  <h4 v-if="col.type === 'subheader'">{{ col.content }}</h4>
                  <div v-if="col.type === 'breakdown'" class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          :color="col.content.color"
                          v-on="on"
                          @click="
                            $refs.tableBreakdown[0].open(
                              col.content.params,
                              col.content.tooltip,
                              col.content.total
                            )
                          "
                        >
                          <v-icon x-small>{{ col.content.icon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ col.content.tooltip }}</span>
                    </v-tooltip>
                  </div>
                  <span v-if="col.type === 'icon'">
                    <v-icon color="green">{{ col.content }}</v-icon></span
                  >
                  <div v-if="col.type === 'action_confirm'" class="text-right">
                    <v-tooltip
                      bottom
                      v-for="(button, buttonIndex) in col.content"
                      v-bind:key="buttonIndex"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="button.action == 'logTime'"
                          x-small
                          depressed
                          :color="button.color"
                          class="mr-2"
                          v-on="on"
                          @click="$refs.logTimeDialog[0].open(button.params)"
                        >
                          <v-icon x-small>{{ button.icon }}</v-icon>
                        </v-btn>

                        <v-btn
                          v-if="button.action == 'closeTask'"
                          x-small
                          depressed
                          :color="button.color"
                          class="mr-2"
                          v-on="on"
                          @click="openClose(button.params)"
                        >
                          <v-icon x-small>{{ button.icon }}</v-icon>
                        </v-btn>

                        <v-btn
                          v-if="button.action == 'closeCall'"
                          x-small
                          depressed
                          :color="button.color"
                          class="mr-2"
                          v-on="on"
                          @click="openCloseCall(button.params)"
                        >
                          <v-icon x-small>{{ button.icon }}</v-icon>
                        </v-btn>

                        <v-btn
                          v-if="button.action == 'completeTask'"
                          x-small
                          depressed
                          :color="button.color"
                          class="mr-2"
                          v-on="on"
                          @click="openComplete(button.params)"
                        >
                          <v-icon x-small>{{ button.icon }}</v-icon>
                        </v-btn>

                        <v-btn
                          v-if="button.action == 'link'"
                          x-small
                          depressed
                          :color="button.color"
                          v-on="on"
                          :to="{ name: button.link, params: button.params }"
                        >
                          <v-icon x-small>{{ button.icon }}</v-icon>
                        </v-btn>

                        <v-btn
                          v-if="button.action == 'sendEmailBalance'"
                          x-small
                          depressed
                          :color="button.color"
                          class="mr-2"
                          v-on="on"
                          @click="openChaseBalance(button.params)"
                        >
                          <v-icon x-small>{{ button.icon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ button.tooltip }}</span>
                    </v-tooltip>
                  </div>
                  <div v-if="col.type === 'buttons'" class="text-right">
                    <v-btn
                      v-for="(button, buttonIndex) in col.content"
                      v-bind:key="buttonIndex"
                      depressed
                      x-small
                      :color="button.color"
                      :to="{ name: button.link, params: button.params }"
                    >
                      <v-icon small>{{ button.icon }}</v-icon>
                    </v-btn>
                  </div>
                  <div v-if="col.type === 'link'">
                    <v-tooltip bottom v-if="col.content.description">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          text
                          :class="col.content.color"
                          v-on="on"
                          :to="{
                            name: col.content.link,
                            params: col.content.params,
                          }"
                        >
                          {{ col.content.text }}
                        </v-btn>
                      </template>
                      <span>{{ col.content.description }}</span>
                    </v-tooltip>
                    <div v-else>
                      <v-btn
                        text
                        :class="col.content.color"
                        :to="{
                          name: col.content.link,
                          params: col.content.params,
                        }"
                      >
                        {{ col.content.text }}
                      </v-btn>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-dialog
        v-model="closeTask.dialog"
        persistent
        max-width="400"
        :retain-focus="false"
      >
        <v-card>
          <v-card-title class="headline">Close Task</v-card-title>
          <v-card-text>Are you sure you want to close this Task?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetClose"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="closeTask.loading"
              @click="saveClose"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="closeCall.dialog"
        persistent
        max-width="400"
        :retain-focus="false"
      >
        <v-card>
          <v-card-title class="headline">Close Call Reminder</v-card-title>
          <v-card-text
            >Are you sure you want to close this Call Reminder?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetCloseCall"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="closeCall.loading"
              @click="saveArchiveCallReminder"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="chaseBalance.dialog" persistent max-width="400">
        <v-card>
          <v-card-title class="headline"> Chase Balance</v-card-title>
          <v-card-text
            >Are you sure you want to chase this balance due?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetChaseBalance">No</v-btn>
            <v-btn
              color="success"
              depressed
              :loading="chaseBalance.loading"
              @click="sendEmailBalance"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="completeTask.dialog"
        persistent
        max-width="400"
        :retain-focus="false"
      >
        <v-card>
          <v-card-title class="headline">Complete Task</v-card-title>
          <v-card-text
            >Are you sure you want to make this as complete?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetComplete"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="completeTask.loading"
              @click="saveComplete"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <log-time-dialog ref="logTimeDialog" :editingWorkChunk="true" />
      <table-breakdown ref="tableBreakdown" />
    </v-col>
  </v-row>
</template>

<script>
import * as Utils from "@/utils/index.js";
import LogTimeDialog from "/src/modules/tasking/views/components/LogTimeDialog.vue";
import TableBreakdown from "./components/Table.vue";

export default {
  components: {
    LogTimeDialog,
    TableBreakdown,
  },

  props: {
    appId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      widgets: this.getWidgets(),
      loading: true,
      widgetContent: {},
      closeTask: {
        dialog: false,
        task: {},
        loading: false,
      },
      closeCall: {
        dialog: false,
        task: {},
        loading: false,
      },
      completeTask: {
        dialog: false,
        task: {},
        loading: false,
      },
      chaseBalance: {
        dialog: false,
        order: {},
        loading: false,
      },
    };
  },

  mounted() {
    this.loadWidgets();
  },

  watch: {
    appId: function () {
      this.loading = true;
      this.widgets = this.getWidgets();
      this.widgetContent = {};
      this.loadWidgets();
    },
  },

  methods: {
    openClose(task) {
      this.closeTask.task = task;
      this.closeTask.dialog = true;
    },

    openCloseCall(task) {
      this.closeCall.task = task;
      this.closeCall.dialog = true;
    },

    openChaseBalance(order) {
      this.chaseBalance.dialog = true;
      this.chaseBalance.order = order;
    },

    resetChaseBalance() {
      this.chaseBalance.dialog = false;
      this.chaseBalance.loading = false;
    },

    sendEmailBalance() {
      this.chaseBalance.loading = true;
      this.$store
        .dispatch("arnprior/ordersStore/sendEmailBalance", {
          appId: this.$route.params.id,
          orderId: this.chaseBalance.order.id,
        })
        .then(() => {
          this.resetChaseBalance();
        })
        .catch(() => {
          this.chaseBalance.loading = false;
        });
    },

    resetClose() {
      this.closeTask.dialog = false;
      this.closeTask.task = {};
      this.closeTask.loading = false;
    },

    resetCloseCall() {
      this.closeCall.dialog = false;
      this.closeCall.task = {};
      this.closeCall.loading = false;
    },

    saveClose() {
      this.closeTask.loading = true;

      this.$store
        .dispatch("workload/closeWorkChunk", {
          appId: this.$route.params.id,
          chunkId: this.closeTask.task.id,
        })
        .then(() => {
          this.resetClose();
        })
        .catch(() => {
          this.closeTask.loading = false;
        });
    },

    saveArchiveCallReminder() {
      this.closeCall.loading = true;

      this.$store
        .dispatch("sbpm/propertiesStore/deleteProperty", {
          appId: this.$route.params.id,
          propertyId: this.closeCall.task.id,
        })
        .then(() => {
          this.resetCloseCall();
        })
        .catch(() => {
          this.closeCall.loading = false;
        });

      this.resetCloseCall();
    },

    openComplete(task) {
      this.completeTask.task = task;
      this.completeTask.dialog = true;
    },

    resetComplete() {
      this.completeTask.dialog = false;
      this.completeTask.task = {};
      this.completeTask.loading = false;
    },

    saveComplete() {
      this.completeTask.loading = true;

      this.$store
        .dispatch("workload/completeWorkChunk", {
          appId: this.$route.params.id,
          chunkId: this.completeTask.task.id,
        })
        .then(() => {
          this.resetComplete();
        })
        .catch(() => {
          this.completeTask.loading = false;
        });
    },

    loadWidgets() {
      const appId = this.appId;

      this.$store
        .dispatch("loadAppModules", {
          appId,
        })
        .then((widgets) => {
          this.widgetContent = widgets;
          this.loading = false;

          Object.keys(widgets).forEach((id) => {
            this.widgets.map((w) => {
              if (w.id === parseInt(id)) {
                w.content = widgets[id];
              }
            });
          });
        });
    },

    getWidgets() {
      return Utils.getAppWidgets(parseInt(this.appId));
    },
  },

  beforeDestroy() {
    this.widgetContent = {};
  },
};
</script>
